function PricingBoxes() {
    return (
        <div>
            <div className='md:flex md:justify-center md:items-center md:flex-wrap grid grid-cols-2 gap-2 p-2'>
                <div
                    className='bg-white md:w-[250px] shadow-md rounded p-2'
                    data-aos='zoom-in-up'
                    data-aos-duration='800'
                    data-aos-once='true'
                >
                    <ul className='text-sm text-center'>
                        <li className='mb-2 font-semibold'>Fine Art Prints</li>
                        <hr className='mb-2' />
                        <li className='mb-4 '>
                            (<span className='text-xs'>from </span>60€)
                        </li>
                        <li className='text-white bg-black rounded mx-14 md:mx-24 mb-4'>A4</li>
                        <li className='mb-4 '>Fine Art 100% Cotton Museum Quality Paper</li>
                        <li className='mb-4'>Signed By The Artist</li>
                        <li className='mb-4'>Limited To 50 Numbered Copies</li>
                        <li className='mb-4'>-</li>
                        <li className='mb-4'>-</li>
                        <li className='mb-4'>Printed Using Pigment Inks</li>
                        <li className='mb-4'>(Shipped In Paper Tube)</li>
                    </ul>
                </div>
                <div
                    className='bg-white md:w-[250px] shadow-md rounded p-2'
                    data-aos='zoom-in-up'
                    data-aos-duration='800'
                    data-aos-delay='200'
                    data-aos-once='true'
                >
                    <ul className='text-sm text-center'>
                        <li className='mb-2 font-semibold'>Fine Art Prints</li>
                        <hr className='mb-2' />
                        <li className='mb-4'>
                            (<span className='text-xs'>from </span>120€)
                        </li>
                        <li className='text-white bg-black rounded mx-14 md:mx-24 mb-4'>A3</li>
                        <li className='mb-4 '>Fine Art 100% Cotton Museum Quality Paper</li>
                        <li className='mb-4'>Signed By The Artist</li>
                        <li className='mb-4'>Limited To 30 Numbered Copies</li>
                        <li className='mb-4'>Certificate Of Authenticity</li>
                        <li className='mb-4'>Hologram Verified</li>
                        <li className='mb-4'>Printed Using Pigment Inks</li>
                        <li className='mb-4'>(Shipped In Paper Tube)</li>
                    </ul>
                </div>
                <div
                    className='bg-white md:w-[250px] shadow-md rounded p-2'
                    data-aos='zoom-in-up'
                    data-aos-duration='800'
                    data-aos-delay='400'
                    data-aos-once='true'
                >
                    <ul className='text-sm text-center'>
                        <li className='mb-2 font-semibold'>Fine Art Prints</li>
                        <hr className='mb-2' />
                        <li className='mb-4'>
                            (<span className='text-xs'>from </span>150€)
                        </li>
                        <li className='text-white bg-black rounded mx-14 md:mx-24 mb-4'>A3+</li>
                        <li className='mb-4 '>Fine Art 100% Cotton Museum Quality Paper</li>
                        <li className='mb-4'>Signed By The Artist</li>
                        <li className='mb-4'>Limited To 30 Numbered Copies</li>
                        <li className='mb-4'>Certificate Of Authenticity</li>
                        <li className='mb-4'>Hologram Verified</li>
                        <li className='mb-4'>Printed Using Pigment Inks</li>
                        <li className='mb-4'>(Shipped In Paper Tube)</li>
                    </ul>
                </div>
                <div
                    className='bg-white md:w-[250px] shadow-md rounded p-2'
                    data-aos='zoom-in-up'
                    data-aos-duration='800'
                    data-aos-delay='600'
                    data-aos-once='true'
                >
                    <ul className='text-sm text-center'>
                        <li className='mb-2 font-semibold'>Fine Art Prints</li>
                        <hr className='mb-2' />
                        <li className='mb-4'>
                            <span>(from </span>200€)
                        </li>
                        <li className='text-white bg-black rounded mx-14 md:mx-24 mb-4'>A2</li>
                        <li className='mb-4 '>Fine Art 100% Cotton Museum Quality Paper</li>
                        <li className='mb-4'>Signed By The Artist</li>
                        <li className='mb-4'>Limited To 10 Numbered Copies</li>
                        <li className='mb-4'>Certificate Of Authenticity</li>
                        <li className='mb-4'>Hologram Verified</li>
                        <li className='mb-4'>Printed Using Pigment Inks</li>
                        <li className='mb-4'>(Shipped In Paper Tube)</li>
                    </ul>
                </div>
                <div
                    className='bg-white md:w-[250px] shadow-md rounded p-2'
                    data-aos='zoom-in-up'
                    data-aos-duration='800'
                    data-aos-delay='800'
                    data-aos-once='true'
                >
                    <ul className='text-sm text-center '>
                        <li className='mb-2 font-semibold'>Fine Art Prints</li>
                        <hr className='mb-2' />
                        <li className='mb-4'>(Call For Price)</li>
                        <li className='text-white bg-orange-400 rounded md:mx-8 mb-4'>Larger Sizes Available</li>
                        <li className='mb-4 '>Fine Art 100% Cotton Museum Quality Paper</li>
                        <li className='mb-4'>Signed By The Artist</li>
                        <li className='mb-4'>-</li>
                        <li className='mb-4'>Certificate Of Authenticity</li>
                        <li className='mb-4'>Hologram Verified</li>
                        <li className='mb-4'>Printed Using Pigment Inks</li>
                        <li className='mb-4'>(Shipped In Paper Tube)</li>
                    </ul>
                </div>
            </div>
            <p className='flex justify-center items-center text-xs mt-6'>
                Above prices do not include VAT 24%, and shipping costs
            </p>
        </div>
    );
}

export default PricingBoxes;
